.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
    color: white;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #272727;
    position: sticky;
    top: 0;
}

nav .title {
    font-size: 1.5rem;
    margin: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: white;
}

nav ul {
    display: flex;

}

nav ul li {
    list-style: none;
}

nav ul li a {
    display: block;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.5rem;
}

nav ul li a:hover {
    background: linear-gradient(to right, #7F00FF, #E100FF);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

nav .menu {
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: white;
    border-radius: 0.2rem;
}

/* phones */
@media only screen and (max-width: 600px) {
    nav .menu {
        display: flex;
    }

    nav {
        flex-direction: column;
        align-items: start;
    }

    nav ul {
        display: none;
        flex-direction: column;
        max-width: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.25rem;
    }

    nav ul.open {
        display: flex;
    }

    nav ul li {
        width: 100%;
        text-align: center;
    }

    nav ul li a {
        margin: 0.2rem 0.5rem;
    }
}